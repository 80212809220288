export default function hr(): Hotelstouch.WebApp.Locale.Keys{
    return {
        name: 'Naziv',
        email: 'Email',
        message: 'Poruka',
        fieldIsRequired: 'Polje je obavezno',
        send: 'Pošalji',
        news: 'Novosti',
        homePage: 'Naslovna',
        haveQuestions: 'Imate pitanja?',
        hitUsUp: 'Javite se',
        contact: 'Kontakt',
        gallery: 'Galerija',
        latestNews: 'Zadnje novosti',
        moreNews: 'Više novosti',
        allRightsReserved: 'Sva prava pridržana',
        notFound: 'Nije pronađeno',
        noData: 'Nema podataka',
        loading: 'Učitavanje',
        aboutUs: 'O nama',
        goToTop: 'Idi na vrh',
        repeatTextAbove: 'Ponovite gornji tekst',
        yourName: 'Vaše ime',
        warningRequiredField: 'Polje je obavezno',
        repeatedTexstIsNotValid: 'Uneseni tekst se ne slaže s gornjim tekstom',
        warningNotEmail: 'Nevažeća adresa e-pošte!',
        iAmSending: 'Šaljem',
        posts: 'Objave',
        username: 'Korisničko ime',
        password: 'Lozinka',
        login: 'Prijava',
        welcome: 'Dobrodošli',
        enterUsernameAndPasswordToLogin: 'Unesite korisničko ime i lozinku za prijavu',
        logout: 'Odjava',
        users: 'Korisnici',
        total: 'Ukupno',
        title: 'Naslov',
        actions: 'Akcije',
        edit: 'Uredi',
        dateCreated: 'Datum kreiranja',
        author: 'Autor',
        new: 'Novi',
        buyTickect: 'Kupi ulaznicu',
        addToOrder: 'Dodaj u narudžbu',
        selectedTicket: 'Odabrana ulaznica',
        numberOfTickets: 'Broj ulaznica',
        unitPrice: 'Jedinična cijena',
        welcomeParagraphTitle: 'Dobro došli na stranice KUD-a Moreška!',
        welcomeParagraphText: `Morešku, viteški ples s mačevima, Korčulani stoljećima ponosno igraju.\nKroz stoljeća svog postojanja se mijenjala te je potpuno saživjela s Korčulom, gdje se jedino i zadržala,\ndobivši uz opće karakteristike i svoje posebne.`,
        buyCardParagraphTitle: 'Kupi ulaznicu online',
        buyCardParagraphText: 'Zbog velikog zanimanja gostiju Korčule, Moreška se izvodi najmanje jednom tjedno. Ovdje, na našim stranicama, možete kupiti online ulaznicu s kojom možete na vrijeme osigurati Vaše mjesto u predstavi',
        buyCardParagraphAdditionalText: 'KUPI ODMAH',
        societyPartsParagraphTitle: 'Sekcije KUD-a',
        societyPartsParagraphText: 'U okviru društva djeluje moreška, glazba i folklor.',
        thankYouParagraphText: `Hvala Vam na posjeti našim stranicama.\nAko imate pitanja, primjedbi, prijedloga ili nešto slično, slobodno\nnam se obratite putem dolje navedenih kontakata.`,
        contactParagraphTitle: 'Kontakt podaci',
        contactParagraphText: `Moreška – Kulturno umjetničko društvo – Korčula\nFoša 2, Korčula\nOIB: 18704976903\nIBAN: HR5824070001100304638`,
        moreskaDetailsFirstParagraph: 'Današnja moreška u dramskom dijelu prikazuje tipičnu borbu za djevojku kroz sukob dvije vojske, dok sukob civilizacija ostaje u pozadini. Izvodi se uz glazbenu pratnju koju je 1937. skladao Krsto Odak, a strukturirana je u osam zasebnih koreografskih dijelova – uvodna plesna figura sfida (izazov) te sedam mačevalačkih figura – kolapa, koje izvode dvije vojske moreškanata, svaki naoružan s dva mača.',
        moreskaDetailsSecondParagraph: 'Moreška je bojni ples s mačevima koji započinje dijalogom između kraljeva i zaručnice bijelog kralja koju je oteo crni kralj. Zatim slijedi sedam figura, kolapa, koje su različite i pune borbene dinamike.  Na kraju ovog plesa vojska bijelog kralja pobjeđuje vojsku crnog kralja te bijeli kralj dobiva zaručnicu u svoj zagrljaj. Tekst dijaloga je star, originalan i korčulanski, a glazbu koja prati Morešku je skladao Krsto Odak.',
        moreskaDetailsAdditionalParagraph: 'Moreška se nekad izvodila svugdje po Mediteranu, a danas se sačuvala samo u gradu Korčuli, gdje se prenosi iz generacije na generaciju više od 400 godina.',
        payMethod: 'Način plaćanja',
        creditCardIsTheOnlyPayMethod: 'Kreditna kartica je jedini način plaćanja',
        creditCard: 'Kreditna kartica',
        buyerInfo: 'Podaci o kupcu',
        firstName: 'Ime',
        lastName: 'Prezime',
        ifYouEnteredAllValuesCorrectlyTheNextStepIsOnlinePayment: 'Ako ste unijeli sve ispravne podatke, sljedeći korak je online plaćanje.',
        continue: 'Nastavak',
        agreeToTermsAndConditionsFirstPart: 'Ovim potvrđujem da sam pročitao i slažem se s',
        agreeToTermsAndConditionsSecondPart: 'i dajem privolu za korištenje mog upisanog emaila, imena i prezimena kako bi KUD Moreška mogao procesirati moju kupljenu ulaznicu i poslati mi istu na navedenu email adresu, i samo u tu svrhu.',
        and: 'i',
        valueNeedsToBeAValidEmail: 'Vrijednost mora biti ispravna email adresa',
        orderSummary: 'Pregled narudžbe',
        contactForm: 'Kontakt forma',
        agreeGDPR: 'Ovime potvrđujem da gore navedene podatke dajem dobrovoljno u svrhu dobivanja odgovora na moj upit.',
        privacyPolicyInstrumental: 'Politikom privatnosti',
        termsOfBusinessInstrumental: 'Uvjetima poslovanja',
        buyTicketOnline: 'Kupi ulaznicu online',
        ifYouDontWantToMissTheShowBuyYourOnlineTicketOnTime: "Ako ne želite propustiti predstavu, kupite svoju online ulaznicu na vrijeme!",
        selectShowDateAndTicketType: 'Odaberi datum predstave i vrstu ulaznice',
        afterSelectingInfoText: "Nakon što odaberete datum predstave prikazati će Vam se raspoloživost karata na odabrani datum, a nakon toga ovisno o raspoloživosti možete odabrati ulaznicu za odrasle, ulaznicu za djecu, kao i oboje, te možete upisati broj pojedinih ulaznica koje želite kupiti.",
        price: 'Cijena',
        selectShowDate: 'Odaberite datum predstave',
        noAvailableTicketsForSelectedDate: 'Nema dostupnih ulaznica za odabrani datum',
        anErrorOccurredWhenGettingPaymentData: 'Dogodila se pogreška prilikom dohvaćanja podataka o naplati',
        goToPaymentGateway: 'Idi na sustav za online naplatu',
        paymentConfirmation: 'Potvrda o kupnji',
        yourPaymentWasSuccessful: 'Vaša kupnja je bila uspješna',
        successfulPurchase: 'Uspješna kupnja',
        dearCustomer: 'Poštovani,',
        thankYouForPurchasingYourTicket: 'Zahvaljujemo Vam se na kupnji ulaznice/a.',
        yourTicketWillBeSendInfoText: 'Vaša ulaznica biti će Vam poslana na email adresu koju ste naveli prilikom unosa podataka, te s njom možete ući na predstavu na odabrani datum. Za sva pitanja Vam stojima na raspolaganju putem podataka navedenih u Kontakt formi na našim web stranicama.',
        seeYouSoon: 'Vidimo se uskoro!',
        orderCanceled: 'Narudžba otkazana',
        yourOrderHasBeenCanceled: 'Vaša narudžba je otkazana',
        printTicket: 'Ispis ulaznice',
        sendInquiry: 'Pošaljite upit',
        ourLegalData: 'Naši zakonski podaci',
        legalActs: 'Pravni akti',
        legalActsDescription: 'Na temelju  članka 13. Zakona o udrugama („Narodne novine“, broj 74/14), Skupština Kulturno-umjetničko društvo „MOREŠKA“ Korčula na Skupštini donjela je STATUT KULTURNO-UMJETNIČKOG DRUŠTVA „MOREŠKA“ KORČULA',
        socialEntities: 'Tijela društva',
        president: 'Predsjednica',
        governingBoard: 'Upravni odbor',
        headOfTheBrassMusicSection: 'Voditelj sekcije limene glazbe',
        headOfTheChoirSection: 'Voditelj sekcije zbora',
        headOfTheFolkloreSection: 'Voditeljica sekcije folklora',
        printBill: 'Ispis računa',
        timeIsUp: 'Vrijeme je isteklo',
        heroSectionText: 'Moreška je romantični bojni ples s mačevima nastao u 12-13. stoljeću na Mediteranu.',
        landingPageText1: 'Korčulanska moreška, bojni ples s mačevima, se kroz stoljeća svog postojanja mijenjala te potpuno saživjela s Korčulom, gdje se jedino i zadržala, dobivši uz opće karakteristike i svoje posebne. Današnja moreška u dramskom dijelu prikazuje tipičnu borbu za djevojku kroz sukob dvije vojske, dok sukob civilizacija ostaje u pozadini. Izvodi se uz glazbenu pratnju koju je 1937. skladao Krsto Odak, a strukturirana je u osam zasebnih koreografskih dijelova - uvodna plesna figura sfida (izazov) te sedam različitih mačevalačkih figura - kolapa.',
        landingPageText2: 'Moreška se nekad izvodila svugdje po Mediteranu, a danas se sačuvala samo u gradu Korčuli, gdje se prenosi iz generacije na generaciju više od 400 godina.',
        firstAndLastName: 'Ime i prezime',
        ticketPrices: 'Cijena ulaznica',
        ticketPricesText: 'Djeca od 4 do 14 godina - 70 kuna (9.29EUR)\nDjeca 14+ i odrasli - 130 kuna (17.25EUR)\nPDV (25%) uključen u cijenu',
        filmingPermissionText: 'Fotografiranje dozvoljeno isključivo za osobne potrebe.',
        privacyPolicy: 'Politika privatnosti',
        termsOfBusiness: 'Uvjeti poslovanja',
        termsOfUseInstrumental: 'Uvjetima korištenja',
        termsPaymentAndDataProtection: 'Uvjeti, plaćanje i korištenje podataka',
        abbreviation: 'Skraćeni naziv',
        hq: 'Sjedište',
        registerNumber: 'Registarski broj',
        tickets: 'Ulaznice',
        fixedConversionRateMessage: 'Fiksni tečaj konverzije 1 € = 7,5345 HRK'
    }
}