import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "../../../components/button/Button";
import Countdown from "../../../components/countdown/Countdown";
import DataMap from "../../../components/data/DataMap";
import ResponsiveSection from "../../../components/responsiveSection/ResponsiveSection";
import getLocales from "../../../locale/Locale";
import { formatCurrency } from "../../../utils/TypeUtil";
import useCartInfo from "../../../webShop/context/CartInfo";
import './OrderSummary.scss';
import moment from "moment";

enum Structure {
    totalRow = 'total_row',
    displayCurrency = 'display_currency',
    accentBox = 'accent_box',
    tableBox = 'table_box',
    orderSummaryHeader = 'order_summary_header'
}

export default function OrderSummary(){

    const { lang } = getLocales()
    const { cart, removeOrderItem, reload } = useCartInfo()

    return <>
        {cart && cart.items && cart.items.length > 0 &&
            <ResponsiveSection class={Structure.accentBox}>
                <div className={Structure.orderSummaryHeader}>
                    <h2>{lang.orderSummary}</h2>
                    <Countdown onTimeout={reload} expirationDateTime={moment(cart.expirationDateTime, 'DD/MM/YYYY HH:mm:ss')} />
                </div>
                <br />
                <div className={Structure.tableBox}>
                    <table>
                        <thead>
                            <tr>
                                <th>{lang.selectedTicket}</th>
                                <th>{lang.numberOfTickets}</th>
                                <th>{lang.total}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        <DataMap data={cart.items} render={({ data: item }) => (
                            <tr key={item.id}>
                                <td>{item.title}</td>
                                <td>{item.quantity}</td>
                                <td>{formatCurrency(item.price)} HRK ({formatCurrency(item.convertedPrice)} {item.displayCurrency})</td>
                                <td>
                                    <Button
                                        style={{ marginLeft: 'auto' }}
                                        media={<FontAwesomeIcon icon={faTrash} />}
                                        onClick={() => removeOrderItem(item.id, item.pibpId)}
                                    />
                                </td>
                            </tr>
                        )}/>
                        </tbody>
                    </table>
                </div>
                <div className={Structure.totalRow}>
                    <div>{lang.total}: {formatCurrency(cart.totalPrice)} HRK<br/></div>
                    <span className={Structure.displayCurrency}>{formatCurrency(cart.convertedTotalPrice)} {cart.displayCurrency}</span>
                </div>
                <br />
                <span style={{ fontStyle: 'italic', fontSize: '0.9rem' }}>{lang.fixedConversionRateMessage}</span>
            </ResponsiveSection>
        }
    </>
}