import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { disableBody, enableBody } from '../../helpers/DomHelpers'
import getLocales, { setCurrentLocale } from '../../locale/Locale'
import Style from '../../styles/Style'
import Button from '../button/Button'
import DataMap from '../data/DataMap'
import Icon from '../icon/Icon'
import IconList from '../icon/IconList'
import './NavBar.scss'

enum Structure {
    pageMenu = 'page_menu',
    menuLogo = 'menu_logo',
    menuLinks = 'menu_links',
    menuItem = 'menu_item',
    menuToggle = 'menu_toggle',
    menuOpened = 'menu_opened',
    smallMenuStrip = 'small_menu_strip',
    languageIcon = 'language_icon'
}

export default function NavBar(props: Hotelstouch.WebApp.Components.NavBar.Import){
    const [menuVisible, setMenuVisible] = useState(false)
    const { currentLocale } = getLocales()

    const toggleMenu = () => {
        if(menuVisible){
            enableBody()
        }
        else{
            disableBody()
        }
        setMenuVisible(!menuVisible)
    }
    const closeMenu = () => {
        enableBody()
        setMenuVisible(false)
    }
    const toggleLocale = () => {
        if(currentLocale === 'hr'){
            setCurrentLocale('en')
        }
        else{
            setCurrentLocale('hr')
        }

        window.location.reload()
    }

    return <div className={`${Structure.pageMenu} ${menuVisible ? `${Structure.menuOpened} ${Style.animations.slideInFromTop}` : ''}`}>
        <div className={Structure.smallMenuStrip}>
            <div className={Structure.menuLogo}><Link to={'/'}>{props.logo}</Link></div>
            <div className={Structure.menuToggle} onClick={toggleMenu}>
                {menuVisible ? <Icon icon={IconList.close} /> : <Icon icon={IconList.menuToggle} />}
            </div>
        </div>

        <div className={`${Structure.menuLinks}`}>
            <DataMap data={props.routes} render={({ data, index }) => {
                return <div className={Structure.menuItem} key={index}>
                    <NavLink to={data.url} onClick={closeMenu}>
                        {data.title}
                    </NavLink>
                </div>
            }} />
        </div>

        <div className={Structure.languageIcon}>
            <Button
                media={<img src={`/images/languages/${currentLocale == 'hr' ? 'en' : 'hr'}.svg`} />}
                onClick={toggleLocale}
            />
        </div>
        
    </div>
}