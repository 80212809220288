export default function en(): Hotelstouch.WebApp.Locale.Keys{
    return {
        name: 'Name',
        email: 'Email',
        message: 'Message',
        fieldIsRequired: 'Field is required',
        send: 'Send',
        news: 'News',
        homePage: 'Home',
        haveQuestions: 'Have questions?',
        contact: 'Contact',
        hitUsUp: 'Hit us up',
        gallery: 'Gallery',
        latestNews: 'Latest news',
        moreNews: 'More news',
        allRightsReserved:  'All rights reserved',
        notFound: 'Not found',
        noData: 'No data',
        loading: 'Loading',
        aboutUs: 'About us',
        goToTop: 'Go to top',
        repeatTextAbove: 'Repeat text above',
        yourName: 'Your name',
        warningRequiredField: 'Field is required',
        repeatedTexstIsNotValid: 'Repeated text does not match the text above',
        warningNotEmail: 'Invalid email!',
        iAmSending: 'Sending',
        posts: 'Posts',
        username: 'Username',
        password: 'Password',
        login: 'Login',
        welcome: 'Welcome',
        enterUsernameAndPasswordToLogin: 'Enter username and password to login',
        logout: 'Logout',
        users: 'Users',
        total: 'Total',
        title: 'Title',
        actions: 'Actions',
        edit: 'Edit',
        dateCreated: 'Date created',
        author: 'Author',
        new: 'New',
        buyTickect: 'Buy a ticket',
        addToOrder: 'Add to order',
        selectedTicket: 'Selected ticket',
        numberOfTickets: 'Number of tickets',
        unitPrice: 'Unit price',
        welcomeParagraphTitle: "Welcome to KUD Moreška's webpage!",
        welcomeParagraphText: `Moreška, a knight's dance with swords, Korčulans have been proudly playing for centuries. Throughout the centuries of its existence, it has changed and fully coexisted with Korčula.\nIt has remained unchanged with all of its special and general characteristics.`,
        buyCardParagraphTitle: 'Buy ticket online',
        buyCardParagraphText: 'Since it is a great tourist Attraction, Moreška is performed at least once a week.Here, on our site, you can buy an online ticket with which you can secure your place in the play.',
        buyCardParagraphAdditionalText: 'BUY NOW',
        societyPartsParagraphTitle: 'KUD sections',
        societyPartsParagraphText: 'Moreška, music and folklore operate within the society.',
        thankYouParagraphText: `Thank you for visiting our site.\nIf you have questions, comments, suggestions or something similar, feel free to\ncontact us via the contacts below.`,
        contactParagraphTitle: 'Contact info',
        contactParagraphText: `Moreška – Kulturno umjetničko društvo – Korčula\nFoša 2, Korčula\nOIB: 18704976903\nIBAN: HR5824070001100304638`,
        moreskaDetailsFirstParagraph: 'Today’s moreška in the dramatic part depicts a typical fight for a girl through a clash of two armies, while the clash of civilizations remains in the background. It is performed with musical accompaniment composed by Krsto Odak in 1937, and is structured in eight separate choreographic parts - the introductory dance figure of the sfid (challenge) and seven fencing figures - collaps, performed by two armies of Moreskans, each armed with two swords.',
        moreskaDetailsSecondParagraph: "Moreška is a battle dance with swords that begins with a dialogue between kings and a white king's fiancée kidnapped by a black king. This is followed by seven figures, collapses, which are different and full of combat dynamics. At the end of this dance, the army of the white king defeats the army of the black king and the white king gets his fiancée in his arms. The text of the dialogue is old, original and Korčula, and the music that accompanies Moreška was composed by Krsto Odak.Moreška is a battle dance with swords that begins with a dialogue between kings and a white king's fiancée kidnapped by a black king. This is followed by seven figures, collapses, which are different and full of combat dynamics. At the end of this dance, the army of the white king defeats the army of the black king and the white king gets his fiancée in his arms. The text of the dialogue is old, original and Korčulan, and the music that accompanies Moreška was composed by Krsto Odak.",
        moreskaDetailsAdditionalParagraph: 'Moreška used to be performed all over the Mediterranean, and today it is preserved only in the town of Korčula, where it is passed down from generation to generation for more than 400 years.',
        payMethod: 'Pay method',
        creditCardIsTheOnlyPayMethod: 'Credit card is the only method of payment',
        creditCard: 'Credit card',
        buyerInfo: 'Customer info',
        firstName: 'First name',
        lastName: 'Last name',
        ifYouEnteredAllValuesCorrectlyTheNextStepIsOnlinePayment: 'If you have entered all the correct information, the next step is online payment',
        continue: 'Continue',
        agreeToTermsAndConditionsFirstPart: 'I hereby confirm that I have read and agree with',
        agreeToTermsAndConditionsSecondPart: 'and I consent to the use of my entered email, name and surname so that KUD Moreška can process my purchased ticket and send it to me at the specified email address, and only for that purpose.',
        and: 'and',
        valueNeedsToBeAValidEmail: 'Value needs to be a valid email',
        orderSummary: 'Order summary',
        contactForm: 'Contact form',
        agreeGDPR: 'I hereby confirm that I provide the above information voluntarily for the purpose of obtaining an answer to my inquiry.',
        privacyPolicyInstrumental: 'Privacy policy',
        termsOfBusinessInstrumental: 'Terms of business',
        buyTicketOnline: 'Buy your ticket online',
        ifYouDontWantToMissTheShowBuyYourOnlineTicketOnTime: "If you don't want to miss the show, buy your online ticket on time!",
        selectShowDateAndTicketType: 'Choose show date and your ticket type',
        afterSelectingInfoText: "Once you select desired show date, you will see the availability of tickets on the selected date. Depending on availability you can choose an adult ticket, children's ticket or both, and you can enter the number of individual tickets you want to buy.",
        price: 'Price',
        selectShowDate: 'Select show date',
        noAvailableTicketsForSelectedDate: 'No available tickets for selected date',
        anErrorOccurredWhenGettingPaymentData: 'An error occurred when getting payment data',
        goToPaymentGateway: 'Go to payment gateway',
        paymentConfirmation: 'Payment confirmation',
        yourPaymentWasSuccessful: 'Your purchase was successful',
        successfulPurchase: 'Successful purchase',
        dearCustomer: 'Dear customer,',
        thankYouForPurchasingYourTicket: 'Thank you for purchasing your ticket/s.',
        yourTicketWillBeSendInfoText: 'Your ticket will be sent to email address that you entered at the checkout. You can use that ticket to enter the show on selected date. If you have any questions feel free to contact us using contact details listed on our website.',
        seeYouSoon: 'See you soon!',
        orderCanceled: 'Order canceled',
        yourOrderHasBeenCanceled: 'Your order has been canceled',
        printTicket: 'Print ticket',
        sendInquiry: 'Send Inquiry',
        ourLegalData: 'Our legal data',
        legalActs: 'Legal acts',
        legalActsDescription: 'Pursuant to Article 13 of the Law on Associations (Official Gazette 74/14), the Assembly of the Cultural and Artistic Association "MOREŠKA" Korčula adopted the STATUTE OF THE CULTURAL AND ART ASSOCIATION "MOREŠKA" KORČULA',
        socialEntities: 'Social entities',
        president: 'President',
        governingBoard: 'Governing board',
        headOfTheBrassMusicSection: 'Head of the brass music section',
        headOfTheChoirSection: 'Head of the choir section',
        headOfTheFolkloreSection: 'Head of the folklore section',
        printBill: 'Print bill',
        timeIsUp: 'Time is up',
        heroSectionText: 'Moreška is a romantic battle dance with swords created in the Mediterranean during 12th-13th century.',
        landingPageText1: 'Moreška is the ancient Korcula sword dance. Following the dialogue  between the kings and the young girl bula - the fiancee of the white king, and the repeated provocations by the black king, seven different sword dances are performed. The figures in each of these dances are unique, involving  both,  skill and danger. The text which accompanies the dance originates from Korčula, and the music is composed by Krsto Odak.',
        landingPageText2: 'Centuries ago the M.oreška was performed all over the Mediterranean littoral, and now it has been played only in the town of Korčula for more than four hundred years.',
        firstAndLastName: 'Name',
        ticketPrices: 'Ticket prices',
        ticketPricesText: 'Children aged 4 to 14 - 70 HRK (9.29EUR)\nChildren aged 14+ and adults - 130 HRK (17.25EUR)\nVAT (25%) included in price',
        filmingPermissionText: 'Photographing allowed only for personal use',
        privacyPolicy: 'Privacy policy',
        termsOfBusiness: 'Terms of business',
        termsOfUseInstrumental: 'Terms of use',
        termsPaymentAndDataProtection: 'Terms, payment and data usage',
        abbreviation: 'Abbreviation',
        hq: 'HQ',
        registerNumber: 'Register number',
        tickets: 'Tickets',
        fixedConversionRateMessage: 'Fixed conversion rate 1 € = 7,5345 HRK'
    }
}