import { Link } from "react-router-dom";
import Button from "../../components/button/Button";
import Divider from "../../components/divider/Divider";
import Footer from "../../components/footer/Footer";
import HeroSection from "../../components/heroSection/HeroSection";
import InfoParagraph from "../../components/infoParagraph/InfoParagraph";
import ResponsiveSection from "../../components/responsiveSection/ResponsiveSection";
import Seo from "../../components/seo/Seo";
import getLocales from "../../locale/Locale";
import Style from "../../styles/Style";
import { getFullUrlFromRelativeUrl } from "../../utils/Http";
import { webShopPath } from "../webshop/WebShop";
import GallerySection from "./Gallery";
import './LandingPage.scss';

enum Structure {
    containerWithBackground = 'container_with_background',
    battleImageWrapper = 'battle_image_wrapper',
    mapResponsive = 'map_responsive',
    buyTicket = 'buy_ticket_paragraph'
}

export const landingPagePath = "/"
export default function LandingPage(){
    const { lang } = getLocales()

    return <>
        <Seo 
            metaDescription={'Morešku, viteški ples s mačevima, Korčulani stoljećima ponosno igraju. Kroz stoljeća svog postojanja se mijenjala te je potpuno saživjela s Korčulom.'}
            socialMediaImageUrl={getFullUrlFromRelativeUrl('/images/hero-cover.jpg')}
        />
        <HeroSection />

        <ResponsiveSection 
            children={
                <InfoParagraph 
                    content={<>
                        <p>{lang.landingPageText1}</p>
                        <p>{lang.landingPageText2}</p>
                    </>}
                />
            }
        />

        <Divider />

        <ResponsiveSection class={`${Structure.buyTicket}`} style={{ height: '300px' }}>
            <Link to={webShopPath}>
                <Button render={lang.buyCardParagraphAdditionalText} primary={true} style={{ background: Style.variables.colorPrimary }} />
            </Link>
        </ResponsiveSection>

        <Divider />

        <GallerySection />

        <Footer />
    </>
}