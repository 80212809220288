import React from "react";
import Divider from "../../components/divider/Divider";
import Page from "../../components/page/Page";
import ResponsiveSection from "../../components/responsiveSection/ResponsiveSection";
import Seo from "../../components/seo/Seo";
import getLocales from "../../locale/Locale";
import './AboutUs.scss'

enum Structure{
    sectionTitle = 'about_us_section_title',
    sectionSubtitle = 'about_us_section_subtitle',
    sectionText = 'about_us_section_text',
    containerPadded = 'container_padded'
}

export const aboutUsPagePath = "/onama";
export default function AboutUs(){
    const { lang } = getLocales()

    return <>
        <Seo title={lang.aboutUs} />
        <Page 
            headerUrl={'/images/impressum.webp'}
            title={'Impressum'}
            subtitle={lang.ourLegalData}
        >
            <ResponsiveSection class={Structure.containerPadded}
                children={<>
                    <div className={Structure.sectionTitle}>{lang.aboutUs}</div>
                    <div className={Structure.sectionText}>{lang.contactParagraphText}</div>
                </>}
            />

            <ResponsiveSection class={Structure.containerPadded}
                children={<>
                    <div className={Structure.sectionTitle}>{lang.legalActs}</div>
                    <p>{lang.legalActsDescription}</p>
                </>}
            />

            <ResponsiveSection class={Structure.containerPadded}
                children={<>
                    <div className={Structure.sectionTitle}>{lang.socialEntities}</div>
                    <p>
                        <div className={Structure.sectionSubtitle}>{lang.president}:</div>
                        {'Bojana Silić Krstulović'}
                    </p>

                    <p>
                        <div className={Structure.sectionSubtitle}>{lang.governingBoard}:</div>
                        {'Sergije Vilović, Mario Sardelić, Marko Trojan, Ivan Šain, Jerko Šegedin, Lovor Lozica'}
                    </p>

                    <p>
                        <div className={Structure.sectionSubtitle}>{lang.headOfTheBrassMusicSection}:</div>
                        {'Alen Krajančić'}
                    </p>

                    <p>
                        <div className={Structure.sectionSubtitle}>{lang.headOfTheChoirSection}:</div>
                        {'Goran Milošević'}
                    </p>

                    <p>
                        <div className={Structure.sectionSubtitle}>{lang.headOfTheFolkloreSection}:</div>
                        {'Janja Lozica'}
                    </p>
                </>}
            />

            <Divider />
        </Page>
    </>
}